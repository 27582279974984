import { useOneTrustContext } from '@accolade/care-analytics/react';
import { sha3_256 } from 'js-sha3';
import { useUserData } from 'src/js/endpoints/users';

type CriteoEvent = {
  event: string;
} & Record<string, unknown>;

type WindowWithCriteo = Window & {
  criteo_q?: CriteoEvent[];
};

const getDeviceType = () => (/iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd');

export const criteoConversion = (hashedEmail: string, reservationUUID: string): void => {
  (window as WindowWithCriteo).criteo_q ||= [];
  const deviceType = getDeviceType();
  // @ts-expect-error we specifically check for the existence of criteo_q above and assign it if it doesn't exist,
  // typescript just can't tell here
  (window as WindowWithCriteo).criteo_q.push(
    { event: 'setAccount', account: 78722 },
    { event: 'setEmail', email: hashedEmail, hash_method: 'sha256' },
    { event: 'setSiteType', type: deviceType },
    {
      event: 'trackTransaction',
      id: reservationUUID,
      item: [
        { id: reservationUUID, price: 100, quantity: 1 },
      ],
    },
  );
};

export const criteoAddToBasket = (hashedEmail: string): void => {
  (window as WindowWithCriteo).criteo_q ||= [];
  const deviceType = getDeviceType();
  // @ts-expect-error we specifically check for the existence of criteo_q above and assign it if it doesn't exist,
  // typescript just can't tell here
  (window as WindowWithCriteo).criteo_q.push(
    { event: 'setAccount', account: 78722 },
    { event: 'setEmail', email: hashedEmail, hash_method: 'sha256' },
    { event: 'setSiteType', type: deviceType },
    {
      event: 'viewBasket',
      item: [
        { id: 'appointment', price: 100, quantity: 1 },
        // add a line for each additional line in the basket
      ],
    },
  );
};

export const criteoViewProduct = (hashedEmail: string): void => {
  (window as WindowWithCriteo).criteo_q ||= [];
  const deviceType = getDeviceType();
  // @ts-expect-error we specifically check for the existence of criteo_q above and assign it if it doesn't exist,
  // typescript just can't tell here
  (window as WindowWithCriteo).criteo_q.push(
    { event: 'setAccount', account: 78722 },
    { event: 'setEmail', email: hashedEmail, hash_method: 'sha256' },
    { event: 'setSiteType', type: deviceType },
    { event: 'viewItem', item: 'plushcare_appointment' },
  );
};

export const useCriteoProductView = () => {
  const { targetingCookiesAllowed } = useOneTrustContext();
  const { data: userData } = useUserData();

  if (!targetingCookiesAllowed || !userData || !userData?.email) return;

  const email = sha3_256(userData.email);
  criteoViewProduct(email);
};
