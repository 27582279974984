import React, { PropsWithChildren } from 'react';
import { PreBookedAppointmentWatcher } from 'src/js/components/bookingFlow/PreBookedAppointmentWatcher';
import { usePCBookingFlow } from 'src/js/components/bookingFlow/pcBookingFlowHook';
import { StepperProvider } from 'src/js/components/Stepper';
import { BookingFlowMasterWrapper } from 'src/js/components/bookingFlow/BookingLayout';
import WithNavigation from 'components/WithNavigation';

export function PrimaryCareBooking({ children }: PropsWithChildren<{}>) {
  return (
    <PrimaryCareBookingInner>
      {children}
    </PrimaryCareBookingInner>
  );
}

function PrimaryCareBookingInner({ children }: PropsWithChildren<{}>) {
  const { length, currentIndex } = usePCBookingFlow();
  return (
    <StepperProvider total={length} current={currentIndex + 1}>
      <WithNavigation>
        <PreBookedAppointmentWatcher />
        <BookingFlowMasterWrapper>
          {children}
        </BookingFlowMasterWrapper>
      </WithNavigation>
    </StepperProvider>
  );
}
