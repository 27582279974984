import Lottie from 'lottie-react';
import styled from '@emotion/styled';
import * as animationData from 'public/json/lottie-activity-indicator.json';
import { colors } from 'src/js/nextgen/plushcare-components';

const LoadingIndicator = (props) => {
  const {
    size,
    className,
    loadingText = 'Loading...',
    subText,
    focused = false,
    flip = false,
    displayActivityIndicator,
    centered = false, // If true, the loading indicator will be centered vertically and horizontally
  } = props;
  return (
    <Styled
      className={className}
      size={size}
      centered={centered}
    >
      {displayActivityIndicator && flip && (
        <div className="activity-indicator" data-testid="activity-indicator-animation">
          <Lottie
            animationData={animationData.default}
            loop
            autoplay
          />
        </div>
      )}

      {loadingText && (
        <p className={`${focused ? 'activity-indicator-text-bold' : 'activity-indicator-text'}`}>{loadingText}</p>
      )}

      {subText && (
        <p className="activity-indicator-text">{subText}</p>
      )}

      {displayActivityIndicator && !flip && (
        <div className="activity-indicator" data-testid="activity-indicator-animation">
          <Lottie
            animationData={animationData.default}
            loop
            autoplay
          />
        </div>
      )}
    </Styled>
  );
};

export default LoadingIndicator;

const Styled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  margin: 0 auto 40px;

  .activity-indicator-text-bold {
    width: 100%;
    margin: 20px 0 10px;
    font-size: ${props => (props.size ? props.size : '16px')};
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.darkBlue};
    font-weight: 500;
  }

  .activity-indicator-text {
    width: 100%;
    margin: 20px 0 10px;
    font-size: ${props => (props.size ? props.size : '16px')};
    text-align: center;
  }

  .activity-indicator {
    width: 70px;
  }

  ${props => (props.centered ? `
    height: 100vh;
    align-content: center;
  ` : '')}
`;
