import { PrimaryCareBooking } from 'components/bookingWrappers/PrimaryCareLayout';
import { MethodSelection } from 'components/booking/MethodSelection';
import { SessionStorage, appUrl } from 'src/js/utils';
import LoadingIndicator from 'src/js/components/BaseComponents/LoadingIndicator';
import CookieStorage from 'src/js/utils/CookieStorage';
import { FLAGS } from 'src/js/abTesting/flags';
import { OptimizelyDecideOption } from '@optimizely/react-sdk';
import { useIsFeatureEnabled } from 'src/js/abTesting/hooks';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useCriteoProductView } from 'src/js/scripts/criteo/eventHandlers';

const Method = () => {
  const router = useRouter();
  const svrCookie = CookieStorage.get('structured_visit_reason');
  const svrInSession = SessionStorage.getItem('structured_visit_reason');
  useCriteoProductView();

  /**
   * svrCookie: structured visit reason set from marketing site
   * svrInSession: structured visit reason set within booking flow
   */
  const structuredVisitReason = svrCookie || svrInSession || null;

  /**
   * Handles coming from marketing site. Marketing site sets the cookie.
   * We write it into session storage upon landing in booking flow.
   */
  if (svrCookie) {
    SessionStorage.setItem('structured_visit_reason', svrCookie);
    CookieStorage.remove('structured_visit_reason');
  }

  // If visit type is not set -> trigger decision else disable decision
  const decideOptions = !structuredVisitReason ? [] : [OptimizelyDecideOption.DISABLE_DECISION_EVENT];
  const { decisionEnabled: svrPageEnabled, isReady, didTimeout } = useIsFeatureEnabled(
    FLAGS.ENABLED_STRUCTURED_VISIT_REASON_PAGE,
    { autoUpdate: true, decideOptions },
  );

  // If visit type is already set OR optimizely timed out, skip the check.
  const [checkComplete, setCheckComplete] = useState(!!structuredVisitReason || didTimeout);

  const svrExperimentCheck = async () => {
    if (!isReady || svrPageEnabled === undefined) return;

    if (svrPageEnabled && !structuredVisitReason) {
      await router.push(appUrl.booking.selectVisitReason);
    }
    setCheckComplete(true);
  };

  if (!checkComplete) {
    svrExperimentCheck();
  }

  return (
    <PrimaryCareBooking>
      {checkComplete || didTimeout ? <MethodSelection />
        : (
          <section>
            <LoadingIndicator displayActivityIndicator centered />
          </section>
        )}
    </PrimaryCareBooking>
  );
};

export default Method;
