import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Analytics from 'src/js/utils/analytics/Analytics';
import {
  clearPayer,
} from 'src/js/actions/InsuranceActions';
import { Button, Title } from 'src/js/nextgen/plushcare-components';
import { BookingStepsHeader } from 'src/js/components/bookingFlow/BookingStepsHeader';
import { usePCBookingFlow } from 'src/js/components/bookingFlow/pcBookingFlowHook';

export const MethodSelection = () => {
  const {
    goToPrev, goToNext, skipNextStep, prefetch,
  } = usePCBookingFlow(null, { prefetchNextPage: false });
  const dispatch = useDispatch();
  useEffect(() => {
    // prefetch both possible decisions
    prefetch('selectAppointment');
    prefetch('insurance');
  }, []);

  const onPayingForMyself = () => {
    dispatch(clearPayer);
    Analytics.track('Web - Method - Click - Paying for myself Btn');
    return skipNextStep();
  };

  const onUsingInsurance = () => {
    Analytics.track('Web - Method - Click - Use my insurance Btn');
    return goToNext();
  };

  return (
    <div role="main">
      <BookingStepsHeader onBackClick={goToPrev} />
      <ServiceContainer>
        <Title>Let&apos;s help you find the lowest cost option.</Title>
        <ButtonsContainer>
          <Button
            type="primary"
            className="primary-pair-button"
            onClick={onUsingInsurance}
            text="Pay with insurance"
            fontSize="20px"
            style={{ margin: '5px 0px' }}
          />
          <Button
            type="primary-tertiary"
            className="primary-pair-button"
            onClick={onPayingForMyself}
            text="Pay without insurance"
            fontSize="20px"
            style={{ margin: '5px 0px' }}
          />
        </ButtonsContainer>
      </ServiceContainer>
    </div>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  ${ButtonsContainer} {
    margin-top: 60px;
  }
`;
