import React from 'react';
import { parseFromISOString } from 'src/js/utils';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { LinkButton } from '@accolade/design-system-react';

import { breakpoints, colors } from 'src/js/nextgen/plushcare-components';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import CookieStorage from '../../../utils/CookieStorage';
import {
  StepperContextStepIndicator, StepperHeader, Timer, useStepper,
} from '../../Stepper';
import { HOLD_APT_TIME_COOKIE_NAME } from '../../../nextgen/utils/constants';

const COOKIE_KEY = HOLD_APT_TIME_COOKIE_NAME;

/* eslint-disable max-len */
const defaultOnBackClick = (router, backLink) => () => router.push(backLink);

interface Props {
  onBackClick?: () => void,
  backLink?: string,
  holdLength?: number,
  sticky?: boolean,
  disableFirstStepBackClick?: boolean,
  customRedirect?: {
    label: string;
    path: string;
  }
}

export const BookingStepsHeader = ({
  onBackClick, backLink, holdLength, sticky = false, disableFirstStepBackClick = true, customRedirect,
}: Props) => {
  const router = useRouter();
  const { selectors: { isFirstStep } } = useStepper();
  const { variant } = useAppVariant();
  const redirectButton = variant === AppVariants.AccoladeCare ? (
    <AdsStyledLinkButton
      to=""
      onClick={(e) => {
        e.preventDefault();
        router.push(customRedirect?.path || '');
      }}
      buttonType="rounded"
      style={{ textDecoration: 'none' }}
    >
      {customRedirect?.label}
    </AdsStyledLinkButton>
  ) : (
    <CustomRedirectContainer onClick={() => router.push(customRedirect?.path || '')}>
      {customRedirect?.label}
    </CustomRedirectContainer>
  );
  return (
    <StepperHeader
      sticky={sticky}
      indicatorComponent={(
        <StepperContextStepIndicator
          onBackClick={onBackClick ?? defaultOnBackClick(router, backLink)}
          shouldDisableFirstStep={isFirstStep() && disableFirstStepBackClick}
        />
)}
    >
      {holdLength && CookieStorage.get(COOKIE_KEY)
    && (
    <Timer
      text="We’re holding your appointment:"
      durationInMinutes={holdLength}
      startTime={parseFromISOString(CookieStorage.get(COOKIE_KEY))}
      fallbackElement={(
        <>
          The appointment is no longer being
          {' '}
          <br />
          {' '}
          held, but you can still try to book.
        </>
)}
    />
    )}
      {
      customRedirect && redirectButton
    }
    </StepperHeader>
  );
};
const AdsStyledLinkButton = styled(LinkButton)`
  float: right;
  margin-right: 15px;
  text-decoration: none;
  @media (min-width: ${breakpoints.desktop}) {
    margin-right: 35px;
  }
`;

const CustomRedirectContainer = styled.div`
  border: 1px solid ${colors.blueCola};
  border-radius: 4px;
  text-align: center;
  float:right;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.darkerBlue};
  padding: 8px;
  cursor: pointer;
  margin-right: 15px;
  @media (min-width: ${breakpoints.desktop}) {
    margin-right: 35px;
  }
`;
